/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import { BrowserView, MobileView } from 'react-device-detect';
import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"
import Theme from "../components/theme"
import Search from "./search"
import NavSearch from "./nav-search"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    siteSearchIndex {
      index
    }
  }
`

const Layout = ({ children, className, props }) => {
  const { site, siteSearchIndex } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata
  const [offsetLogoNav, setOffsetLogoNav] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    // TODO we need to make the mobileview switch based on this useEffect trigger... so when width is less than 960, change classes instead of using BrowserView and MobileView
    if (window.innerWidth > 960) {
      window.onscroll = () => {
        setOffsetLogoNav(window.pageYOffset);
        setOffset(window.pageYOffset);
      };
    }
  }, []);

  return (
    <div className="primary-container">
      <Header>
        <Logo title={siteTitle} className={offset > 30 ? navbarClasses[0] : navbarClasses[1]} sx={{
          variant: "variants.navLogoColor",
        }} />
        <div sx={offsetLogoNav > 30 ? logoNav : hideLogoNav}>
          <span className="sideNavLogo">
            <Link to="/" sx={{
              variant: "variants.navLogoColor",
            }}>Assumptions Aside</Link>
          </span>
        </div>
        <BrowserView>
          <div sx={layoutStyle.nav}>
            <div sx={{ display: ["flex", "flex", "flex", "none"] }}>
              <Search searchIndex={siteSearchIndex.index} logoState={"Eder"} />
              {/* Make another logo component here to display on the scroll... */}
            </div>
            <Navigation className="absolute-center" />
          </div>
        </BrowserView>
        <MobileView>
          <div sx={layoutStyle.navMobile}>
            <div sx={{ display: ["flex", "flex", "flex", "none"] }}>
              <Search searchIndex={siteSearchIndex.index} logoState={"Eder"} />
              {/* Make another logo component here to display on the scroll... */}
            </div>
            <Navigation />
          </div>
        </MobileView>
        <div sx={layoutStyle.appearance}>
          <span className={offset > 30 ? showHide[0] : showHide[1]}>
            <Search searchIndex={siteSearchIndex.index} />
          </span>
          <span className={offset > 30 ? showHide[1] : showHide[0]}>
            <NavSearch searchIndex={siteSearchIndex.index} />
          </span>
        </div>
        <div sx={layoutStyle.appearance}>
          <Theme />
        </div>
      </Header>
      <main className={"container " + className}>{children}</main>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout

const hideLogoNav = {
  display: "none"
}

const logoNav = {
  display: "block",
  padding: "10px",
  display: "flex",
  justifyContent: "left",
  fontSize: "23px",
  fontWeight: "bold",
  alignItems: "center",
  position: "absolute",
}

const layoutStyle = {
  appearance: {
    display: ["none", "none", "none", "flex"],
    alignItems: "center",
    justifyContent: "end",
  },
  nav: {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  navMobile: {
    paddingTop: "0px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}

const navbarClasses = [
  "site-logo hide",
  "site-logo show",
];

const showHide = [
  "hide",
  "show",
]