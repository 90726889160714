/** @jsx jsx */
import { jsx } from "theme-ui";
import { useColorMode } from "theme-ui";
import { FiMoon, FiSun } from "react-icons/fi";
import { BrowserView, MobileView } from 'react-device-detect';

// TODO Clean this up later (get rid of MobileView and BrowserView)
const Theme = () => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <div sx={themeStyles.modeOption}>
      <div sx={themeStyles.modeSection}>
        <BrowserView>
          <button
            onClick={e => {
              setColorMode(colorMode === "default" ? "dark" : "default")
            }} className="colorModeButton"
          >
            <div sx={themeStyles.modeIcons}>
              <div>{colorMode === "default" ? <FiMoon /> : <FiSun sx={{
                variant: "variants.navIcons",
              }} />}</div>
              <div sx={themeStyles.modeText}>
                {colorMode === "default" ? "Dark" : "Light"}
              </div>
            </div>
          </button>
        </BrowserView>
        <MobileView>
          <button
            onClick={e => {
              setColorMode(colorMode === "default" ? "dark" : "default")
            }} className="colorModeButtonMobile"
          >
            <div sx={themeStyles.modeIcons}>
              <div>{colorMode === "default" ? <FiMoon /> : <FiSun sx={{
                variant: "variants.navIcons",
              }} />}</div>
              {/* <div sx={themeStyles.modeText}>
                {colorMode === "default" ? "Dark" : "Light"}
              </div> */}
            </div>
          </button>
        </MobileView>
      </div>
    </div>
  )
}
export default Theme

const themeStyles = {
  modeSection: {
    position: ["none", "absolute"],
  },
  modeOption: {
    button: {
      /* top: "-2.3rem",
      right: "0rem",
      position: "absolute",
      zIndex: "1",
      fontSize: "25px",
      bg: "transparent",
      border: "none",
      cursor: "pointer",
      mt: "-5px",
      "&:hover": {
        color: "#ff8989",
      }, */
    },
  },
  modeIcons: {
    display: "flex",
    alignItems: "center",
    mt: "10px",
  },
  modeText: {
    color: "#8d8d8d",
    fontSize: "16px",
    display: ["block", "block", "block", "none"],
    /* p: " 0 10px", */
    mt: "-5px",
    letterSpacing: "1px",
  },
}
