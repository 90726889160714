/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri"
import Theme from "../components/theme"
import { variationPlacements } from "@popperjs/core"

const MenuItems = [
  {
    path: "/",
    title: "HOME",
  },
  {
    path: "/about",
    title: "ABOUT",
  },
  {
    path: "/blog",
    title: "BLOG",
  },
  {
    path: "/contact",
    title: "CONTACT",
  },
]

const ListLink = props => (
  <li>
    <Link activeStyle={{ color: "#d69929" }} to={props.to}>{props.children}
    </Link>
  </li >
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const listMenuItems = MenuItems.map((menuItem, index) => (
      <ListLink key={index} to={menuItem.path} sx={{
        variant: "links.postLink",
      }}>
        {menuItem.title}
      </ListLink>
    ))
    return (
      <nav className="site-navigation" sx={navStyle.menu}>
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")} sx={{
            variant: "variants.navIcons",
          }}
        >
          <div className="icon-menu-line">
            <RiArrowDownSLine />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          {listMenuItems}
          <div sx={navStyle.border}></div>
          <div sx={navStyle.theme} className="centerElement">
            <Theme />
          </div>
        </ul>
      </nav>
    )
  }
}

export default Navigation

const navStyle = {
  active: {
    variant: "variants.navColor",
  },
  menu: {
    ul: {
      variant: "variants.navColor",
    },
  },
  theme: {
    display: ["block", "block", "block", "none"],
    p: " 25px 20px 20px",
  },
  border: {
    bg: "borderColor",
    borderTop: "1px solid transparent",
    display: ["block", "block", "block", "none"],
  },
}
