import React from "react"
import { Link } from "gatsby"

const Logo = props => {

  return (
    <div className={props.className}>
      <Link to="/" sx={{
        variant: "variants.navLogoColor",
      }}>{props.title}</Link>
    </div>
  )
}

export default Logo